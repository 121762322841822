import React, {useState} from 'react'
import './style.css'
import circle2 from '../../../assets/images/circle-2.png'
import woman from '../../../assets/images/woman.png'
import mastImg from './../../../assets/images/skull.png'
import coverImg from './../../../assets/images/cover-img-2.png'
import Link from 'gatsby-link';


const TRENDS = [
  {
    name: "base",
    label: "Description",
    opportunity: "Select a trend to view opportunities and risks",
    risk: "Select a trend to view opportunities and risks"
  },
  {
    name: "funeral-costs",
    label: "Rising Funeral Costs",
    opportunity: "There is an opportunity for cooperative community run death service and funeral industry. All it takes is to remove some of the taboo around death.",
    risk: "The continued rise of funeral costs, despite the fact that many of the green funeral practices should be less expensive is just further fueling our desire to hide from death."
  },
  {
    name: "suicide",
    label: "Rising Suicide Rates",
    opportunity: "Rising suicide rates in the US, especially among marginalized groups, are highlighting the need for changes in the conversation around life and death. There is a clear need for a stronger mental health industry.",
    risk: "The internet and social media has shown us the clear differences between the haves and the have nots. Early research shows a correlation to a higher suicide rate. If we continue down this path, we may be looking at a huge spike."
  },
  {
    name: "baby-boomers",
    label: "Increasing Mortality Rates",
    opportunity: "Baby boomers beginning to move into end of life care is creating a lot of room for innovation and huge opportunities in the death care space.",
    risk: "The flip side of this coin is the increased burden on a health care industry that is already ill-equipped to deal with death and an aging population that may be easily manipulated into purchasing products and services that they may not want or need."
  },
  {
    label: "Decreased Birth Rates",
    name: "birth-rate",
    opportunity: "Select a trend to view opportunities and risks",
    risk: "Select a trend to view opportunities and risks"
  },
  {
    label: "Increased End of Life Medical Costs",
    name: 'medical-costs',
    opportunity: "End of life medical costs are outrageous, in large part because of our unpreparedness. There is a virtuous cycle ready to get kicked off here by just changing the conversation around death in the US.",
    risk: "With baby boomers nearing end of life care coupled with an already strained medical system, we run the risk of completely overwhelming the system with excess cost, due to the simple fact that we are not ready to accept our fates."
  },
  {
    label: "Increased Review of Laws and Regulations",
    name: "regulation",
    opportunity: "Because of the rise of new technologies, and ways to dispose of human remains, many states and localaties are revisiting their death laws. Now is the time to make sure that we get it right.",
    risk: "Because laws and regulations, especially in the more taboo fields don't get revisited often, there is a narrow window in which laws could be passed that could help enact meaningful change."
  },
  {
    label: "The Death Tech Boom",
    name: 'death-tech',
    opportunity: "Scientific breakthroughs, AI, and the SaaS industry and all creating new ways to handle the process of dying, the handling of our remains, and what happens after our bodies are gone. Some of these innovations have immense opportunities to create a better space and conversation around death.",
    risk: "With every new scientific breakthrough there is a new opporuntity to monetize it. The monetization of death is a large part of what has created such an unhealthy atmosphere around it. New innovations around death combined with our worst capitalist instincts have the ability to send us down an even darker path."
  }
]

const TrendDetail = ({trend}) => {
  console.log('TREND', trend)
  return (
    <div className="trend-detail-outer">
      <h2>{trend.label}</h2>
      <h3>Opportunity</h3>
      <div className="trend-detail">{trend.opportunity}</div>
      <h3>Risk</h3>
      <div className="trend-detail">{trend.risk}</div>
    </div>
  )
}

const Trends = () => {
  const [currentTrend, setCurrentTrend] = useState('base')
 
  const showTrend = (x) => {
    setCurrentTrend(x)
  }
  const thisTrend = () => {
    const ts = TRENDS.filter(x =>x.name == currentTrend  ) 
    console.log('TS', ts)
    return ts[0];
  }

  return (
    <div className="letter-editor trends-page">
       <img className="p2-circle" src={circle2} />
       <div className="left-side-trends">
       <div className="article-back ab-scanning-hits"><Link to='/research'>&larr; Research </Link></div>
        <h1>Trend Information and Risk/Opportunity assessment</h1>
        <div className="research-trends section-content">
        <div className="de-trends-outer">
              <h4 className="cs-trends-title">TRENDS</h4>
              <ul className="de-trends">
                  <li onClick={()=> showTrend('funeral-costs')}>Increasing Funeral Costs</li>
                  <li onClick={()=> showTrend('suicide')}>Rising Suicide Rate</li>
                  <li onClick={()=> showTrend('baby-boomers')}>Rising rate of death due to baby boomers age</li>
                  {/* <li onClick={()=> showTrend('birth-rate')}>Lower birth rates</li> */}
                  <li onClick={()=> showTrend('medical-costs')}>Increasing end of life medical costs</li>
                  <li onClick={()=> showTrend('regulation')}>Increased challenging/review of laws and regulations</li>
                  <li onClick={()=> showTrend('death-tech')}>Rise of Death Tech</li>
              </ul>
          </div>
        
        </div>
      </div>
      <div>
       <img className="letter-mast" src={mastImg} />
       {currentTrend && <TrendDetail trend={thisTrend()} /> }
      </div>
    </div>
  )
}

export default Trends